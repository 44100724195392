import SuccessContactUsFormImage from 'assets/baners/successContactUsForm.svg';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import Icon from 'components/Icon';
import t from '@guestyci/localize/t.macro/t.macro';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [create('xs')]: {
      padding: '0 0',
    },
    [create('md')]: {
      padding: '0 70px',
    },
  },
  titleWrapper: {
    margin: '30px 0 5px 0',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 36,
    '& > img': {
      cursor: 'pointer',
    },
  },
  image: {
    fontSize: 200,
  }
}));

const ContactFormSuccess = ({ onClose }) => {
  const { root, inner, titleWrapper, closeButton, image } = useStyles();

  return (
    <div className={root}>
      <div className={closeButton}>
        <Icon icon="close" size={12} onClick={onClose} />
      </div>
      <div className={inner}>
        <SuccessContactUsFormImage className={image} />

        <TextField variant="h2" className={titleWrapper}>
          {t('Thank you!')}
        </TextField>

        <TextField variant="h4">{t('Your message has been successfully sent')}</TextField>

        <TextField variant="h4">{t(`We'll be in touch with you shortly`)}</TextField>
      </div>
    </div>
  );
};

export default ContactFormSuccess;
